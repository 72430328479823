@import url('https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,200..1000;1,200..1000&display=swap');

html, body {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: "Mulish", sans-serif;
  font-size: 16px;
  font-weight: 500;
  color: #454545;
}

* {
  padding: 0;
  margin: 0;
  letter-spacing: 0.5px;
  box-sizing: border-box;
  transition: all 0.3s ease;
}

:root {
  --themeColorOne: #0d2a69;
  --themeColorTwo: #0799dd;
}

#root {
  height: 100vh;
  position: relative;
}

a {
  text-decoration: none;
}

img{
  width: 100%;
  height: 100%;
  display: block;
}

img.fit-cover{
  object-fit: cover;
}

.swiper-pagination-bullet-active{
  background-color: var(--themeColorTwo);
}

.services{
  scroll-margin-top: 80px;
}

.go-to-top{
  width: 50px;
  height: 50px;
  background: linear-gradient(135deg, var(--themeColorOne), var(--themeColorTwo));
  border-radius: 50%;
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: fixed;
  bottom: -50px;
  right: 40px;
  z-index: 200;
}

.go-to-top img{
  transform: rotate(-90deg);
}

.go-to-top.show{
  bottom: 40px;
}

.go-to-top:hover img{
  transform: translateY(-6px) rotate(-90deg);
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: linear-gradient(to bottom, var(--themeColorOne), var(--themeColorTwo));
}